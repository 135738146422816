<template>
	<v-list-group prepend-icon="mdi-lan-connect">
		<template v-slot:activator>
			<v-list-item-title>
				Conference Mode
			</v-list-item-title>
		</template>
		<start-conference></start-conference>
		<archived-conferences></archived-conferences>
	</v-list-group>
</template>

<script>
	import StartConference from "@c/conference/StartConference.vue";
	import ArchivedConferences from "@c/conference/ArchivedConferences.vue";
	export default {
		name: "ConferenceNavigationItem",
		data: () => {
			return {
				active: null
			};
		},
		computed: {},
		components: {
			ArchivedConferences,
			StartConference
		}
	};
</script>
