import surveyUtilities from "@/assets/surveyUtilities";
export default {
	firestorePath: "sectionResponses",
	firestoreRefType: "collection",
	moduleName: "sectionResponses",
	statePropName: "data",
	namespaced: true,
	sync: {
		defaultValues: {
			multiple: false,
			status: "not_started",
			users: {},
			completion_rate: 0,
			score: 0,
			data: {}
		}
	},
	state: {},
	getters: {
		canDelegate(state) {
			return (id) => {
				let response = state.data[id];
				return !["delegated", "complete"].includes(response.status);
			};
		}
	},
	mutations: {},
	actions: {
		async makeCopy({dispatch, state}, {from, assessment}) {
			await dispatch("fetchById", from);
			let oldResponse = state.data[from];
			let newResponse = {...oldResponse};
			delete newResponse.id;
			newResponse.logs = [];
			newResponse.completion_rate = 0;
			newResponse.status = "in_progress";
			newResponse.users = [];
			newResponse.assessment = assessment;
			return await dispatch("insert", newResponse);
		},
		updateData({dispatch, state}, {data, id, completion_rate}) {
			let update = {
				id,
				data,
				completion_rate
			};
			let response = state.data[id];
			if (response.status == "not_started") {
				update.status = "in_progress";
			}
			update.score = surveyUtilities.getSectionScore(state.data[id]) || 0;
			dispatch("patch", update)
				.then(() => true)
				.catch((e) => e);
		}
	}
};
