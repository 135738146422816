<template>
	<v-navigation-drawer
		v-model="drawer"
		:mini-variant="mini"
		touchless
		permanent
		app
	>
		<logged-in v-if="loggedIn"></logged-in>
		<login v-else></login>
		<v-divider></v-divider>

		<v-list dense>
			<nav-item
				:expanded="!mini"
				v-for="item in items"
				:key="item.title"
				:link="item.link"
				v-bind="item"
			>
			</nav-item>
			<respondent-navigation
				:expanded="!mini"
				v-if="isRespondent"
			></respondent-navigation>
			<continuous-model-navigation v-if="isLeadership"></continuous-model-navigation>
			<approver-navigation
				:expanded="!mini"
				v-if="isApprover"
			></approver-navigation>
			<lead-navigation :expanded="!mini" v-if="isLeadership"></lead-navigation>
			<nav-item
				v-if="isPollee"
				:expanded="!mini"
				icon="mdi-crosshairs-question"
				link="/polls"
				text="Polls"
			>
			</nav-item>
			<help v-if="loggedIn"></help>
		</v-list>
		<template v-slot:append>
			<v-list dense>
				<conference-navigation-item
					:mini="mini"
					v-if="isLead"
				></conference-navigation-item>

				<nav-item
					v-if="organisation.id"
					:link="`/conference/${organisation.id}`"
					icon="mdi-video"
					help="The Video Room is a quick, always-on way of chatting to other members of your team"
					text="Video Room"
				></nav-item>
				<nav-item
					:expanded="!mini"
					link="/contact"
					icon="mdi-at"
					text="Contact Us"
				></nav-item>
				<chat :expanded="!mini"></chat>
				<logout :expanded="!mini" v-if="loggedIn"></logout>
				<nav-item
					@click="mini = !mini"
					:expanded="!mini"
					:text="$t('buttons.collapse')"
					icon="mdi-menu"
				></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<script>
	import Chat from "@c/ui/Chat.vue";
	import LoggedIn from "@c/auth/LoggedIn";
	import Login from "@c/auth/Login";
	import Logout from "@c/auth/Logout.vue";
	import NavItem from "@c/navigation/Item";
	import LeadNavigation from "@c/navigation/Lead.vue";
	import RespondentNavigation from "@c/navigation/Respondent.vue";
	import Help from "@c/navigation/Help.vue";
	import ApproverNavigation from "@c/navigation/Approval.vue";
	import ConferenceNavigationItem from "@c/conference/NavigationItem.vue";
	import ContinuousModelNavigation from "@c/continuous-model/Navigation";

	export default {
		name: "Sidebar",
		computed: {
			loggedIn() {
				return this.$store.state.auth.logged_in;
			},
			organisation() {
				return this.$store.state.organisation.data;
			},
			isRespondent() {
				return this.$store.getters.isRespondent;
			},
			isApprover() {
				return this.$store.getters.isApprover;
			},
			isLead() {
				return this.$store.getters.isLead;
			},
			isLeadership() {
				return this.$store.getters.isLeadership;
			},
			isPollee() {
				return this.$store.getters.isPollee;
			}, 
			haveAssessments( ) {
				return this.$store.getters.assessmentsCanAccess;
			}
		},
		data() {
			return {
				drawer: true,
				items: [
					{text: "Home", icon: "mdi-home", link: "/"},
					{text: "About", icon: "mdi-information", link: "/about"}
				],
				mini: false
			};
		},
		components: {
			Login,
			Chat,
			LoggedIn,
			Logout,
			NavItem,
			ApproverNavigation,
			LeadNavigation,
			ContinuousModelNavigation,
			RespondentNavigation,
			ConferenceNavigationItem,
			Help
		},
		inject: ["isMobile"],
		created() {
			if (this.isMobile) {
				this.mini = true;
			}
		}
	};
</script>
