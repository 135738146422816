export default {
    hide_answered: false,
    current: {
        section: false, 
        response: false
    }, 
    colors: [
        "#193758",
        "#009B55",
        "#E2371F",
        "#009FE3",
        "#F8F400",
        "#64BE28",
        "#78007A",
        "#F1AF00",
        "#C00067",
        "#00775B",
        "darkgrey"
    ]
}