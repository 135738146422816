import _ from "lodash";

import store from "../store";
import utilities from "./utilities";
export default {
	objectPrecedence: ["response", "organisation"],
	categoryPrecedence: ["provider", "service"],
	splitBy: "service",
	processResponse(response) {
		let survey = store.state.sections.data[response.section];
		if (!survey || !survey.questions) {
			return;
		}
		let questions = store.state.questions.data;
		let questionTemplates = store.state.questionTemplates.data;
		return survey.questions.map((q) => {
			let question = questions[q];
			if (!question) {
				return false;
			}
			let questionTemplate = questionTemplates[question.question_template];
			let answer = response.data[q];
			let processed_answer = "";
			let score = "";
			if (questionTemplate) {
				var options = questionTemplate.answer_options;
				if (answer) {
					processed_answer = this.processAnswer(answer, options);
					score = this.getScore(answer, questionTemplate);
				}
			}
			return {
				...response,
				response: response.id,
				answer,
				indicator: question.indicator,
				question_template: question.question_template,
				question: q,
				score,
				processed_answer,
			};
		});
	},
	processAnswer(answer, answerOptions) {
		if (typeof answer == "object") {
			let answers = answer.map((a) => {
				return answerOptions[a].text;
			});
			answer = answers.join(", ");
		} else {
			let answerOption = answerOptions[answer];
			if (answerOption) {
				answer = answerOption.text;
			}
		}
		return answer;
	},
	getScore(answer, question_template) {
		let score = null;
		if (!question_template.scored) {
			return score;
		}
		let answerOption = question_template.answer_options[answer];
		if (answerOption) {
			if (answerOption.scored) {
				score = answerOption.score;
			}
		}
		return null == score ? null : Number(score);
	},
	getSectionScore( response ){
		const self = this;
		let questions = store.state.questions.data;
		let questionTemplates = store.state.questionTemplates.data;

		let scores = Object.keys( response.data ).filter( q => response.data[q] !== "not-applicable" ).map( questionId => {
			let question = questions[questionId];
			let question_template = questionTemplates[question.question_template];
			return self.getScore( response.data[questionId], question_template )
		} )
		scores = scores.filter( score => score !== null );
		return _.mean( scores )
	},
	averageResponse(arr, answer_options) {
		
		let results = arr.map((v) => v.score);
		results = results.filter((v) => null !== v);
		results = results.filter((v) => '' !== v);
		if (!results.length) {
			return "-";
		}
		let average = utilities.average(results);
		let scoreLabels = Object.fromEntries(
			answer_options.map((a) => [Number(a.score), a.text])
		);
		let scores = Object.keys(scoreLabels);
		let closest = utilities.closestValue(average, scores);
		return scoreLabels[closest] || "";
	},
	questionHasConditions(question) {
		if (!question) {
			return false;
		}
		if (question.rules && question.rules.length) {
			return true;
		}
		return false;
	},
	splitRule(rule) {
		let rules = rule.identifier.split(".");
		return {
			section: rules[0],
			question: rules[1],
		};
	},
	filterQuestion(rules, objects) {
		let passedAll = [];
		if (rules) {
			let categoryRules = rules.filter((a) => a.category);
			if (categoryRules.length) {
				passedAll = categoryRules.map((rule) => {
					let passed = true;
					this.objectPrecedence.forEach((o) => {
						if (typeof objects[o][rule.category] !== "undefined") {
							if (!utilities.testRule(rule, objects[o])) {
								passed = false;
							}
						}
					});
					return passed;
				});
			}
		}
		const result = !passedAll.includes(false);
		return result;
	},
	calculateValue( calculation, response ){
		let data = response.data;
		let questions = store.state.sections.data[response.section].questions;
		let statement = calculation.split(" ");
		statement = statement.map( v => {
			if( questions.includes(v) ){
				return data[v] || 0;
			}
			return v;
		})
		statement = statement.join(" ");
		let value = 0;
		try { 
			value = eval(statement) 
		} catch (e) {
			console.log( e );
		}
		return value;
	},
	isVisible(id, currentResponse) {

		let question = store.state.questions.data[id];

		if (this.questionHasConditions(question)) {
			let responses = store.state.sectionResponses.data;
			let test = true;
			let questionRules = question.rules.filter((rule) => !rule.category);
			questionRules.forEach((rule) => {
				let identifiers = this.splitRule(rule);
				let response = currentResponse;
				// if this is querying a different section
				if (identifiers.section !== currentResponse.section) {
					// find that section from the main assessemtn
					response = store.state.assessment.data.responses[identifiers.section];
					// if that section in the main assessment is split 
					if (typeof response == "object") {
						// if this response is also split, take the response with the same value as this one
						if (currentResponse[this.splitBy]) {
							response = response[currentResponse[this.splitBy]];
						} 
						// otherwise just take the first response
						else {
							response = Object.values(response)[0];
						}
					}
					response = responses[response];
				}
				let passed = this.evaluateRule(rule, response.data);
				if (!passed) {
					test = passed;
				}
			});
			return test;
		} else {
			return true;
		}
	},

	evaluateRule(rule, data) {
		if (!rule) {
			return true;
		}
		if (!data) {
			return false;
		}

		var visible = false;
		var targetAnswer = rule.value;
		if (!targetAnswer) {
			return true;
		}
		var targetQuestion = this.splitRule(rule).question;
		if (targetQuestion == "any") {
			visible = Object.values(data).findIndex((v) => v == targetAnswer) > -1;
		} else {
			var value = data[targetQuestion];
			if (rule.operator == "in") {
				visible = targetAnswer.indexOf(value) > -1;
			} else {
				var test = `'${value}' ${rule.operator} '${targetAnswer}'`;
				visible = eval(test);
			}
		}
		return visible;
	},
	hideAnswered(questions, response) {
		let hide = store.state.hide_answered;
		let values = response.data;
		if (!hide) {
			return questions;
		} else {
			return questions.filter(
				(q) => values[q] == "" || typeof values[q] == "undefined"
			);
		}
	},
	currentVisibleQuestions(){
		let current = store.state.current;
		if(!current.section){
			return [];
		}
		let section = store.state.sections.data[current.section];
		let response = store.state.sectionResponses.data[current.response];
		
		let questions = section.questions.filter((q) => {
			let y = this.testCategoryRules(q);
			return y && this.isVisible(q, response);
		});
		questions = this.hideAnswered(questions, response);
		return questions;
	},
	testCategoryRules(id) {
		let current = store.state.current;
		const question = store.state.questions.data[id];
		const organisation = store.state.organisation.data;
		let response = store.state.sectionResponses.data[current.response];

		let passed = true;
		if (this.questionHasConditions(question)) {
			passed = this.filterQuestion(question.rules, {
				response: response,
				organisation: organisation
			});
		}
		return passed;
	},

};
