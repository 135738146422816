import Vue from "vue" 
export default {
    GO_TO( state, {section, response} ){
        Vue.set(state, "current", {section, response} )
    },
    CLEAR_CURRENT( state ){
        Vue.set(state, "current", {section: null, response: null } )
    },
    HIDE_ANSWERED(state, value ){
        Vue.set(state, "hide_answered", value );
    }
}