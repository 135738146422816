import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// export default new Vuetify({
//     themes: {
//         light: {
//             lightest: "#cdcdcd",
//             primary: "#1478C6"
//         },
//         dark: {
//         }
//     }
// });

const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				lightest: "#f7f7f7",
				primary: "#124987",
				secondary: "#b0bec5",
				accent: "#009FE3",
				error: "#b71c1c",
				green: "#00775B",
				lightblue: "#009FE3",
				darkblue: "#003087",
			},
		},
	},
});
export default vuetify;
