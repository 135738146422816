import _ from "lodash";
export default {
	firestorePath: "assessments",
	firestoreRefType: "collection",
	moduleName: "assessments",
	statePropName: "data",
	namespaced: true,
	serverChange: {
		convertTimestamps: {
			created_at: "%convertTimestamp%", // default
			updated_at: "%convertTimestamp%" // default
			// define each field like so ↑ in an object here
		}
	},
	state: {},
	sync: {
		defaultValues: {
			stage: "in_progress",
			status: "published",
			responses: {},
			users: {},
			organisation: null
		}
	},
	getters: {
		inProgress(state) {
			return Object.values(state.data).find( a => a.stage == "in_progress" && a.status == 'published')
		},
		submitted(state) {
			return Object.values(state.data).find( a => a.stage == "submitted" && a.status == 'published')
		},
		mostRecent(_, getters) {
			return getters.ordered[0];
		},
		ordered(state, __, rootState ) {
			__;
			let collection = state.data;
			let organisation = rootState.organisation.data.id;
			collection = Object.values(collection);
			collection = collection.filter((a) => a.status == "published");
			collection = collection.filter(
				(a) => a.organisation == organisation
			);

			collection = _.sortBy(collection, "created_at");
			collection = _.reverse(collection);
			return collection;
		}
	},
	mutations: {},
	actions: {
		async setDeleted({dispatch, state}, id) {
			let assessment = state.data[id];
			await Promise.all(
				Object.values(assessment.responses).map(async (response1) => {
					if (typeof response1 == "string") {
						await dispatch(
							"sectionResponses/patch",
							{
								id: response1,
								status: "deleted"
							},
							{root: true}
						);
					} else {
						Object.values(response1).forEach(async (response2) => {
							await dispatch(
								"sectionResponses/patch",
								{
									id: response2,
									status: "deleted"
								},
								{root: true}
							);
						});
					}
				})
			);
			return await dispatch("patch", {
				id,
				status: "deleted"
			});
		},
		async copy({dispatch, rootState}, {mode, from, description}) {
			const doc = {
				organisation: rootState.organisation.data.id,
				users: {[rootState.auth.data.id]: "leadRespondent"},
				description
			};
			const newAssessment = await dispatch("insert", doc);
			if (mode == "empty") {
				return newAssessment;
			}
			if (mode == "copy" && from) {
				let responses = {};
				const assessment = rootState.assessments.data[from];
				await Promise.all(
					Object.keys(assessment.responses).map(async (section) => {
						if (typeof assessment.responses[section] == "string") {
							_.set(
								responses,
								section,
								await dispatch(
									"sectionResponses/makeCopy",
									{
										from: assessment.responses[section],
										assessment: newAssessment
									},
									{root: true}
								)
							);
						} else {
							Object.keys(assessment.responses[section]).map(
								async (service) => {
									_.set(
										responses,
										`${section}.${service}`,
										await dispatch(
											"sectionResponses/makeCopy",
											{
												from: assessment.responses[section][service],
												assessment: newAssessment
											},
											{root: true}
										)
									);
								}
							);
						}
					})
				);
				await dispatch("patch", {id: newAssessment, responses});
				return newAssessment;
			}
		}
	}
};
