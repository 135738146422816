<template>
	<span>
		<slot name="button" @click="dialog = true"></slot>
		<v-dialog v-model="dialog" :width="size" :fullscreen="fullscreen">
			<v-card>
				<v-card-title>{{ title }}</v-card-title>
				<v-card-text>
					<slot name="dialog-body"></slot>
				</v-card-text>
				<v-card-actions>
					<v-btn
						v-if="cancelBtn"
						@click="cancel"
						text
						color="grey"
						>{{ $t("buttons.cancel") }}</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn
						:disabled="disabled"
						v-if="submitBtn"
						@click="submit"
						color="primary"
					>
						Submit
					</v-btn>
					<slot v-else name="dialog-buttons"></slot>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</span>
</template>

<script>
// slots:
// button = the trigger button
// dialog-body = the main content of the dialog
// dialog-buttons = buttons displayed only if the submit button isn't included

// emits:
// submit = on button press
export default {
	name: "MwDialog",
	props: {
		value: { type: Boolean },
		title: { type: String },
		fullscreen: { type: Boolean },
		small: { type: Boolean, default: false }, // sets width of dialog to 360, defaults to false (500px)
		large: { type: Boolean, default: false }, // sets width of dialog to 740, defaults to false (500px)
		cancelBtn: { type: Boolean }, // whether to show the cancel button
		submitBtn: { type: Boolean }, // whether to show the submit button
		disabled: {type: Boolean, default: false}
	},
	data: () => {
		return {
			dialog: true,
		};
	},
	computed: {

		size(){
			return this.small ? 360 : this.large ? 740 : 500
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(value) {
				this.dialog = value;
			},
		},
		dialog(value) {
			if (this.value !== value) {
				this.$emit("input", value);
			}
		},
	},
    methods: {
		cancel(){
			this.$emit('input', false);
			this.$emit('cancel');
		},
        submit(){
            this.$emit('submit')
			this.dialog = false;
        }
    }
};
</script>
