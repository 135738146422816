export default {
	firestorePath: "users",
	firestoreRefType: "collection",
	moduleName: "users",
	statePropName: "data",
	namespaced: true,
	state: {},
	sync: {
		defaultValues: {
			status: "published", 
			registration_method: "colleague"
		}
	},
	getters: {
		respondents(state, _, __, rootGetters) {
			var user = rootGetters["auth/id"];
			return Object.fromEntries(
				Object.keys(state.data)
					.filter((id) => id !== user)
					.map((id) => [id, state.data[id]])
			);
		},
	},
	mutations: {},
	actions: {
		fetchRespondents({ dispatch }, id) {
			return dispatch("fetchAndAdd", {
				clauses: {
					limit: -1,
					where: [
						["status", "in", ["published", "updated", "new", "pending"]],
						["organisations", "array-contains", id],
					],
				},
			})
		},
	},
};
