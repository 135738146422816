<template>
	<div>
		<h2>Welcome!</h2>
		<p>
			You’ve arrived here because {{ organisation.name }} is taking part in
			Scottish Government’s Digital Maturity Self-Assessment Initiative.
		</p>
		<p>
			{{ organisation.name }}’s assessment lead has requested your help with one
			of the sections of the assessment.
		</p>
		<render-content
			id="homedelegate"
		></render-content>
		<contact-information v-if="loggedIn"></contact-information>
	</div>
</template>

<script>
	import ContactInformation from "@c/ui/ContactInformation";
	import RenderContent from "@c/ui/RenderContent";
	export default {
		name: "Home",
		components: {
			RenderContent,
			ContactInformation
		},
		computed: {
            organisation(){
                return this.$store.state.organisation.data
            },
			loggedIn() {
				return this.$store.state.auth.data.id;
			}
		}
	};
</script>
