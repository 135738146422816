<template>
	<v-app>
		<top-nav></top-nav>
		<sidebar></sidebar>
		<v-main>
			<v-sheet flat class="pa-8">
				<router-view v-if="allowed" />
			</v-sheet>
		</v-main>
		<assessment-warning v-if="!isApprover"></assessment-warning>
		<toast></toast>
		<start-screen-share-support v-if="!isMobile"></start-screen-share-support>
		<cookies-notice></cookies-notice>
	</v-app>
</template>

<style lang="less">
	@import url("./assets/css/main.less");
</style>

<script>
	import Vuex from "vuex";
	import TopNav from "@c/navigation/Top.vue";
	import StartScreenShareSupport from "@c/video/StartScreenShareSupport";
	import Sidebar from "@c/navigation/Side.vue";
	import Toast from "@c/notices/Toast.vue";
	import AssessmentWarning from "@c/notices/AssessmentWarning.vue";
	import CookiesNotice from "@c/notices/CookiesNotice.vue";
	export default {
		name: "App",
		data: () => ({}),
		computed: {
			...Vuex.mapState({
				state: (state) => state
			}),
			hasOrg() {
				return this.$store.state.organisation.data.id;
			},
			inProgressAssessment() {
				return this.$store.getters["assessments/inProgress"];
			},
			mostRecentAssessment() {
				return this.$store.getters["assessments/mostRecent"];
			},
			isLead() {
				return this.$store.getters.isLead;
			},
			isLeadership() {
				return this.$store.getters.isLeadership;
			},
			isApprover() {
				return this.$store.getters.isApprover;
			},
			allComplete() {
				return this.$store.getters.allComplete;
			},
			allowed() {
				if (this.$route.meta.organisation && !this.hasOrg) {
					return false;
				}
				if (this.$route.meta.lead && !this.isLead && !this.isLeadership) {
					return false;
				}
				if (this.$route.meta.complete && !this.allComplete) {
					return false;
				}
				return true;
			},
			isMobile() {
				return this.$vuetify.breakpoint.smAndDown;
			}
		},
		components: {
			TopNav,
			Sidebar,
			Toast,
			StartScreenShareSupport,
			CookiesNotice,
			AssessmentWarning,
		},
		provide() {
			return {
				isMobile: this.isMobile
			};
		},
		watch: {
			inProgressAssessment: {
				immediate: true,
				handler(v) {
					if (!v) {
						return;
					}
					this.$store.dispatch("assessment/start", v.id);
				}
			},
			mostRecentAssessment: {
				immediate: true,
				handler(v) {
					if (!v) {
						return;
					}
					if( this.isApprover ){
						this.$store.dispatch("assessment/start", v.id);
					}
				}
			}
		},
		created() {
			document.title = this.$store.getters["config/assessmentTitle"];
		}
	};
</script>
