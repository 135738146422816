import utilities from "@/assets/utilities.js";
export default {
	userId(state) {
		return state.auth.data.id;
	},
	role(state, getters) {
		if (!state.assessment.data.users) {
			return false;
		}
		return state.assessment.data.users[getters.userId];
	},
	isLead(_, getters) {
		return getters.role == "leadRespondent";
	},
	isRespondent(_, getters) {
		return getters.role == "leadRespondent" || getters.role == "delegate";
	},
	isDelegate(_, getters) {
		return getters.role == "delegate";
	},
	isApprover(state, getters) {
		return state.organisation.data.approvers.includes(getters.userId);
	},
	isLeadership(state, getters) {
		return state.organisation.data.leadership.includes(getters.userId);
	},
	isStakeholder(state, getters) {
		return (
			state.auth.data.organisation_stakeholder || getters.isUniversalStakeholder
		);
	},
	isUniversalStakeholder(state) {
		return state.auth.data.universal_stakeholder;
	},
	canAccessWholeAssessment(_, getters) {
		return getters.isLeadership || getters.isApprover || getters.isStakeholder;
	},
	assessmentsCanAccess(state, getters) {
		let user = state.auth.data.id;
		let assessments = Object.keys(state.assessments.data);
		if (getters.isLeadership) {
			return assessments;
		}
		return assessments.filter((a) => state.assessments.data[a].users[user]);
	},
	canAccessAssessment(_, getters) {
		return (
			getters.isLeadership ||
			getters.isApprover ||
			getters.isStakeholder ||
			getters.isLead ||
			getters.isDelegate
		);
	},
	isPollee(_, getters) {
		return getters["polls/myActivePolls"].length > 0;
	},
	allComplete() {
	//state,
	// getters
		// let order = getters.order;
		// let responses = order.map((item) => item.response);
		// let incomplete = responses.find((id) => {
		// 	let response = state.sectionResponses.data[id];
		// 	if (!response) {
		// 		return true;
		// 	}
		// 	return response.status !== "complete";
		// });
		// if (incomplete) {
		// 	return false;
		// }
		return true;
	},
	mySections(state, getters) {
		let sections = state.config.data.sections;
		if (!sections) {
			return [];
		}
		let sectionData = state.sections.data;
		let user = state.auth.data.id;
		sections = sections.filter((s) => sectionData[s]);
		let filter = state.sections.filter;
		let responses = state.assessment.data.responses;
		if (!responses) {
			return [];
		}
		let sectionResponses = state.sectionResponses.data;
		sections = sections.filter((s) => responses[s]);
		if (getters.isDelegate) {
			sections = sections.filter((s) => {
				return Object.values(sectionResponses)
					.filter((r) => r.users[user] == "delegate")
					.find((r) => r.section == s);
			});
		}
		if (filter.length) {
			sections = sections.filter((s) => filter.includes(s));
		}
		return sections;
	},
	isCurrentlyMultiple(state) {
		var current = state.current.response;
		if (!current) {
			return false;
		}
		var response = state.sectionResponses.data[current];
		return response.multiple;
	},

	currentMultipleRule(state) {
		var responses = state.assessment.data.responses;
		var current = state.current;
		var id = Object.keys(responses[state.current.section]).find((b) => {
			return responses[current.section][b] == current.response;
		});
		var rule = state.config.data.multiple[current.section].find(
			(a) => a.value == id
		);
		return rule;
	},
	approverOrder(state) {
		let sections = state.config.data.sections;
		var responses = state.assessment.data.responses;
		if (!responses) {
			return [];
		}
		let order = [];
		sections.forEach((section) => {
			var config = state.config.data;
			var response = responses[section];
			if (config.multiple[section]) {
				config.multiple[section].forEach((rule) => {
					let passed = utilities.testRule(rule, state.organisation.data);
					if (passed) {
						if (response[rule.value]) {
							if (state.sectionResponses.data[response[rule.value]]) {
								order.push({
									section: section,
									response: response[rule.value]
								});
							}
						}
					}
				});
			} else {
				order.push({section: section, response: response});
			}
		});
		return order;
	},
	order(state, getters) {
		let sections = getters.mySections;
		if (!sections) {
			return [];
		}
		var responses = state.assessment.data.responses;
		if (!responses) {
			return [];
		}
		let order = [];
		sections.forEach((section) => {
			var config = state.config.data;
			var response = responses[section];
			if (config.multiple[section]) {
				config.multiple[section].forEach((rule) => {
					let passed = utilities.testRule(rule, state.organisation.data);
					if (passed) {
						if (response[rule.value]) {
							if (state.sectionResponses.data[response[rule.value]]) {
								order.push({
									section: section,
									response: response[rule.value]
								});
							}
						}
					}
				});
			} else {
				order.push({section: section, response: response});
			}
		});
		return order;
	},
	getRuleForResponse(state) {
		return (id) => {
			let response = state.sectionResponses.data[id];
			let rules = state.config.data.multiple[response.section];
			if (!rules) {
				return;
			}
			let matchedRule = rules.find((rule) => {
				return response[rule.category] == rule.value;
			});
			return matchedRule || false;
		};
	},
	currentDelegateForSection(state) {
		return function(id) {
			var response = state.sectionResponses.data[id];
			if (!response) {
				return false;
			}
			return (
				Object.keys(response.users).find(
					(user) => response.users[user] == "delegate"
				) || false
			);
		};
	},
	canCurrentlyEditAssessment(state) {
		var assessment = state.assessment.data;
		if (!assessment) {
			return false;
		}
		let can = ["in_progress"];
		let cannot = ["approved", "submitted"];
		if (can.includes(assessment.stage)) {
			return true;
		}
		if (cannot.includes(assessment.stage)) {
			return false;
		}
		return;
	},
	canCurrentlyEditResponse(state, getters) {
		var response = state.sectionResponses.data[state.current.response];
		if (!response) {
			return false;
		}
		if (response.status == "complete") {
			return false;
		}
		if (response.status == "delegated") {
			if (getters.isLead) {
				return false;
			}
			if (getters.isDelegate) {
				var user = getters.currentDelegateForSection(response.id);
				if (getters["auth/id"] == user) {
					return true;
				}
				return false;
			}
		}
		if (getters.isLead) {
			return true;
		}
	},
	canCurrentlyEdit(_, getters) {
		return (
			getters.canCurrentlyEditAssessment && getters.canCurrentlyEditResponse
		);
	},
	sectionAllows(state) {
		return function({id, action}) {
			var section = state.sections.data[id];
			if (!section) {
				return false;
			}
			if (!section.allow) {
				return true;
			}
			return section.allow.includes(action);
		};
	},
	staffSurveySection(state) {
		var id = state.config.data.staff_survey;
		return state.sections.data[id] || false;
	},
	canPoll(state, getters) {
		if (!getters.canCurrentlyEdit) {
			return false;
		}
		return (
			(getters.isLead || getters.isDelegate) &&
			getters.sectionAllows({id: state.current.section, action: "polling"})
		);
	},
	completionRate(state, getters) {
		const order = getters.order;
		if (!order) {
			return 0;
		}
		let rates = order.map((o) => {
			const response = state.sectionResponses.data[o.response];
			let rate = response ? response.completion_rate : 0;
			return Number(rate);
		});
		rates = rates.filter((r) => !isNaN(r));
		return Math.floor(utilities.average(rates));
	}
};
