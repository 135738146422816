export default {
	firestorePath: "emails",
	firestoreRefType: "collection",
	moduleName: "emails",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		reminder({rootGetters, dispatch, rootState}, id) {
			let user = rootState.users.data[id];
			var mail = {
				toUids: [id],
				template: {
					data: {
						name: user.name,
						sender: rootState.auth.data.name,
						organisation: rootState.organisation.data.name
					},
					name: "reminder"
				},
				assessment: rootGetters["assessment/id"]
			};
			return dispatch("insert", mail);
		},
		delegate({rootGetters, dispatch, rootState}, {responseId, userId}) {
			let response = rootState.sectionResponses.data[responseId];
			if (!response) {
				throw new Error("Problem delegating");
			}
			let section = rootState.sections.data[response.section];
			let user = rootState.users.data[userId];

			var mail = {
				toUids: [userId],
				template: {
					data: {
						name: user ? user.name : "colleague",
						sectionName: section.name,
						sectionIntro: section.introduction || "",
						sender: rootState.auth.data.name,
						organisation: rootState.organisation.data.name
					},
					name: "delegate"
				},
				assessment: rootGetters["assessment/id"]
			};
			return dispatch("insert", mail);
		},
		leadRespondent({rootGetters, dispatch, rootState}, id) {
			let user = rootState.users.data[id];
			var mail = {
				toUids: [id],
				template: {
					data: {
						name: user.name,
						organisation: rootState.organisation.data.name
					},
					name: "leadRespondent"
				},
				assessment: rootGetters["assessment/id"]
			};
			return dispatch("insert", mail);
		},
		contactForm({rootGetters, dispatch}, {message, email}) {
			let subject = `New contact form from your website: ${email}`;
			var mail = {
				to: "sg@dma.works",
				message: {
					subject: subject,
					html: message
				},
				type: "contact_form",
				assessment: rootGetters["assessment/id"] || null
			};
			return dispatch("insert", mail);
		},
		registrationForm({dispatch}, data) {
			var mail = {
				to: "sg@dma.works",
				template: {
					name: "mOZUE17YLYZhdyfGLZ0o",
					data
				},
				type: "contact_form"
			};
			return dispatch("insert", mail);
		},
		poll({rootGetters, rootState, dispatch}, id) {
			let poll = rootState.polls.data[id];
			let response = rootState.sectionResponses.data[poll.response];
			let section = rootState.sections.data[response.section];
			let user = rootState.users.data[poll.user];

			var mail = {
				toUids: [poll.user],
				response: poll.response,
				template: {
					data: {
						name: user.name,
						sectionName: section.name,
						sectionIntro: section.introduction.replace(/<[^>]*>?/gm, ""),
						sender: rootState.auth.data.name,
						organisation: rootState.organisation.data.name
					},
					name: "newpoll"
				},
				assessment: rootGetters["assessment/id"]
			};

			return dispatch("insert", mail);
		}
	}
};
