<template>
	<v-list-item class="px-2">
		<v-list-item-avatar color="primary">
			<span v-if="initials" class="white--text">
				{{ initials }}
			</span>
			<v-icon v-else>mdi-account-circle</v-icon>
		</v-list-item-avatar>
		<v-list-item-content>
			<v-list-item-title>
				<router-link to="/profile">
					{{ auth.name || $t("auth.logged_in") }}
				</router-link>
			</v-list-item-title>
			<switch-org></switch-org>
		</v-list-item-content>
	</v-list-item>
</template>

<style lang="less"></style>

<script type="text/javascript">
	import Vuex from "vuex";
	import SwitchOrg from "@c/navigation/SwitchOrg.vue";
	export default {
		name: "LoggedIn",
		computed: {
			...Vuex.mapState({
				auth: (state) => state.auth.data
			}),
			initials() {
				var name = this.auth.name;
				if (!name) {
					return false;
				}
				return name
					.split(" ")
					.map((a) => a.charAt(0))
					.join("");
			}
		},
		components: {SwitchOrg}
	};
</script>
"
