<template>
	<v-snackbar v-model="value" text color="warning" multi-line timeout="-1">
		<v-btn text @click="value = false" icon><v-icon>mdi-close</v-icon> </v-btn>
		You are viewing a submitted assessment. Click
		<router-link to="all-assessments">here</router-link> to view all available
		assessments.
	</v-snackbar>
</template>

<script>
	export default {
		name: "AssessmentWarning",
		data: () => {
			return {
				value: false
			};
		},
		computed: {
			inProgressAssessment() {
				return this.$store.getters["assessments/inProgress"];
			},
			currentAssessment() {
				return this.$store.state.assessment.data;
			},
			viewingDatedAssessment() {
				return (
					this.currentAssessment.id &&
					this.currentAssessment.stage !== "in_progress"
				);
			}
		},
        watch: {
            viewingDatedAssessment: {
                immediate: true, 
                handler(v){
                    this.value = v;
                }
            }
        }
	};
</script>
