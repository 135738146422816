export default {
	firestorePath: "badges",
	firestoreRefType: "collection",
	moduleName: "badges",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
	},
	mutations: {},
	actions: {
	},
};
