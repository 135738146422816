<template>
	<div>
		<nav-item
			:expanded="expanded"
			link="/approve"
			icon="mdi-tooltip-check"
			:text="$t('assessment.approve')"
		></nav-item>
	</div>
</template>
<script>
	import NavItem from "@c/navigation/Item";

	export default {
		name: "Approval",
		props: {
			expanded: {type: Boolean}
		},
		computed: {
			organisation() {
				return this.$store.state.organisation.data;
			}
		},
		data() {
			return {};
		},
		components: {
			NavItem,
		}
	};
</script>
