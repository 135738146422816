import {arrayUnion} from "vuex-easy-firestore";

export default {
	async delegateTo({dispatch, state}, {response, user, notify = true}) {
		let current_user = state.auth.data.id;
		let r = state.sectionResponses.data[response];
		if (notify) {
			await dispatch("emails/delegate", {responseId: response, userId: user});
		}
		await dispatch(`sectionResponses/patch`, {
			id: response,
			users: {[user]: "delegate"},
			status: "delegated",
			logs: arrayUnion({
				action: "delegated",
				by: current_user,
				to: user,
				at: new Date(),
				completion_rate: r.completion_rate,
				score: r.score
			})
		});
		await dispatch(`assessment/patch`, {
			users: {[user]: "delegate"}
		});
		return true;
	},
	async revokeFrom({dispatch, state}, {response, user}) {
		let current_user = state.auth.data.id;
		let r = state.sectionResponses.data[response];
		await dispatch(`sectionResponses/patch`, {
			id: response,
			status: "revoked",
			logs: arrayUnion({
				action: "revoked",
				by: current_user,
				to: user,
				at: new Date(),
				completion_rate: r.completion_rate,
				score: r.score
			})
		});
		await dispatch("sectionResponses/delete", `${response}.users.${user}`);
		return true;
	},
	async returnDelegation({dispatch, state}, {response}) {
		let current_user = state.auth.data.id;
		let r = state.sectionResponses.data[response];
		await dispatch(`sectionResponses/patch`, {
			id: response,
			status: "returned",
			logs: arrayUnion({
				action: "returned",
				by: current_user,
				at: new Date(),
				completion_rate: r.completion_rate,
				score: r.score
			})
		});
		await dispatch(
			"sectionResponses/delete",
			`${response}.users.${current_user}`
		);
		return true;
	},
	async rejectDelegation({dispatch, state}, {response, name, email, comments}) {
		let current_user = state.auth.data.id;
		let r = state.sectionResponses.data[response];
		await dispatch(`sectionResponses/patch`, {
			id: response,
			status: "rejected",
			logs: arrayUnion({
				action: "rejected",
				by: current_user,
				at: new Date(),
				completion_rate: r.completion_rate,
				score: r.score,
				recommendation:  {
					name,
					email,
					comments
				}
			})
		});

		await dispatch(
			"sectionResponses/delete",
			`${response}.users.${current_user}`
		);
		return true;
	}
};
