<template>
	<div>
		<render-content
			id="homeleadrespondent"
		></render-content>
		<contact-information v-if="loggedIn"></contact-information>
	</div>
</template>

<script>
	import ContactInformation from "@c/ui/ContactInformation";
	import RenderContent from "@c/ui/RenderContent";
	export default {
		name: "Home",
		components: {
			RenderContent,
			ContactInformation
		},
		computed: {
            organisation(){
                return this.$store.state.organisation.data
            },
			loggedIn() {
				return this.$store.state.auth.data.id;
			}
		}
	};
</script>
