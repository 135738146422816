export default {
	firestorePath: "conferences",
	firestoreRefType: "collection",
	moduleName: "conferences",
	statePropName: "data",
	namespaced: true,
	state: {
		data: {
		} 
	},
	getters: {},
	actions: {
		fetchByAssessment({dispatch}, id ){
			dispatch(
				"fetchAndAdd",
				{ clauses: { where: [["assessment", "==", id]] } },
			)
		}
	}, 
};
