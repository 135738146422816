export default {
	firestorePath: "categoryOptions",
	firestoreRefType: "collection",
	moduleName: "categoryOptions",
	statePropName: "data",
	namespaced: true,
    serverChange: {
		
	},
	state: {
	},
	getters: {
		getName(state) {
			return (id) => {
				let cat = state.data[id];
				if( !cat ){
					return "";
				}
				return cat.name;
			}
		}
	},
	mutations: {
	},
	actions: {
	},
};
