<template>
	<div>
		<nav-item
			:expanded="expanded"
			link="/organisation-profile"
			icon="mdi-hospital-building"
			:text="$t('organisation.profile')"
		>
		</nav-item>
		<nav-item v-if="assessment"
			:expanded="expanded"
			link="/my-team/"
			icon="mdi-account-group"
			:text="$t('collaboration.my_team')"
		></nav-item>
		<v-list-group prepend-icon="mdi-clipboard-edit-outline" v-model="active">
			<template v-slot:activator>
				<v-list-item-title>
					{{ $t("staff_survey.title") }}
				</v-list-item-title>
			</template>
			<nav-item
				:expanded="expanded"
				link="/staff-survey/dashboard/"
				icon="mdi-chat"
				text="Survey"
			></nav-item>
			<nav-item
				:expanded="expanded"
				link="/staff-survey/responses/"
				icon="mdi-chart-bar"
				text="Responses"
			></nav-item>
		</v-list-group>
	</div>
</template>
<script>
	import NavItem from "@c/navigation/Item";
	export default {
		name: "LeadNavigation",
		data: () => {
			return {
				active: null
			};
		},
		props: {
			expanded: {type: Boolean, default: true} // is this currently expanded
		},
		computed: {
			assessment() {
				return this.$store.getters["assessment/id"];
			},

			organisation() {
				return this.$store.state.organisation.data;
			},
			organisations() {
				return this.$store.state.organisations.data;
			},
			multipleOrganisations() {
				return Object.keys(this.organisations).length > 1;
			}
		},
		components: {
			NavItem
		}
	};
</script>
