<template>
	<div>
		<template v-if="isLead || isDelegate">
      <lead-welcome v-if="isLead"></lead-welcome>
      <delegate-welcome v-if="isDelegate"> </delegate-welcome>
    </template>
		<template v-else>
			<render-content id="home"></render-content>
		</template>
	</div>
</template>

<script>
	import DelegateWelcome from "@c/ui/DelegateWelcome";
	import RenderContent from "@c/ui/RenderContent";
	import LeadWelcome from "@c/ui/LeadWelcome.vue";
	export default {
		name: "Home",
		components: {
			RenderContent,
			LeadWelcome,
			DelegateWelcome
		},
		computed: {
			loggedIn() {
				return this.$store.state.auth.data.id;
			},
			isLead() {
				return this.$store.getters.isLead;
			},
			isDelegate() {
				return this.$store.getters.isDelegate || this.$store.getters.isPollee;
			}
		}
	};
</script>
