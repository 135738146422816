import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import firebase from "firebase";

import mwutils from "@/assets/utilities.js";
import mwsurveyutils from "@/assets/surveyUtilities.js";
import VueI18n from "vue-i18n";
import english from "@/assets/translations/english.js";
import IdleVue from "idle-vue";

Vue.config.productionTip = false;

Vue.prototype.mwutils = mwutils;
Vue.prototype.mwsurveyutils = mwsurveyutils;

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
Sentry.init({
	Vue,
	dsn: "https://832bee328fe842aa86d3024975ec2d77@o397452.ingest.sentry.io/4505030419415040",
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router), 
			tracingOrigins: ["localhost", "my-site-url.com", /^\//],
		}),
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

Vue.use(VueMoment, {
	moment,
});

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: "en",
	messages: {
		en: english,
	},
});

var firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_CONFIG_APIKEY,
	authDomain: process.env.VUE_APP_FIREBASE_CONFIG_AUTHDOMAIN,
	projectId: process.env.VUE_APP_FIREBASE_CONFIG_PROJECTID,
	storageBucket: process.env.VUE_APP_FIREBASE_CONFIG_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_CONFIG_MESSAGINGSENDERID,
	appId: process.env.VUE_APP_FIREBASE_CONFIG_APPID,
	measurementId: process.env.VUE_APP_FIREBASE_CONFIG_MEASUREMENTID,
};


firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ experimentalForceLongPolling: true, merge: true }); 

const appCheck = firebase.appCheck();
appCheck.activate(
	process.env.VUE_APP_FIREBASE_SITE_KEY,
  true);
firebase.analytics();

firebase.getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};

firebase.auth().onAuthStateChanged((user) => {
	if (user) {
		store.dispatch("auth/start");
	}
});

Vue.use(IdleVue, {
	store,
	idleTime: 1200000,
});

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
