<template>
	<div>
		<nav-item
			:expanded="expanded"
			link="/all-assessments"
			icon="mdi-clipboard-list"
			text="All Assessments"
		></nav-item>

	</div>
</template>
<script>
import NavItem from "@c/navigation/Item";

export default {
	name: "ContinuousModelNavigation",
	props: {
		expanded: { type: Boolean, default: true }, // is this currently expanded
	},

	computed: {
		organisation(){
			return this.$store.state.organisation.data
		}
	},
	data() {
		return {};
	},
	components: {
		NavItem,
	},
};
</script>
