<template>
	<mw-dialog
		title="Select Organisation"
		small
		v-if="multiOrg"
		v-model="dialog"
		cancel-btn
		submit-btn
		@submit="switchOrg"
	>
		<template v-slot:button>
			<v-list-item-subtitle>
				<v-btn
					text
					x-small
					@click="dialog = true"
					style="text-align: left; padding: 0px"
				>
					Change Organisation
				</v-btn>
			</v-list-item-subtitle>
		</template>
		<template v-slot:dialog-body>
			<p>Select the organsiation for which you are answering questions below:</p>
			<v-checkbox
				v-for="(organisation, id) in organisations"
				:key="id"
				:value="id"
				v-model="selected"
				:label="organisation.name"
			>
			</v-checkbox>
		</template>
	</mw-dialog>
</template>
<script>
	import MwDialog from "@c/ui/MwDialog";
	export default {
		name: "SwitchOrg",
		data: () => {
			return {
				dialog: null,
				selected: null
			};
		},
		computed: {
			organisations() {
				return this.$store.state.organisations.data;
			},
			multiOrg() {
				return Object.keys(this.organisations).length > 1;
			},
			current() {
				return this.$store.state.organisation.data.id;
			}
		},
		components: {
			MwDialog
		},
		watch: {
			dialog() {
				this.selected = this.current;
			}
		},
		methods: {
			switchOrg() {
				this.$store.dispatch("organisation/start", {id: this.selected, reset: true} );
			}
		}
	};
</script>
