export default {
	firestorePath: "questions",
	firestoreRefType: "collection",
	moduleName: "questions",
	statePropName: "data",
	namespaced: true,
	serverChange: {
		addedHook: function(updateStore, doc, _, { dispatch }) {
			if( doc.question_template ){
				dispatch("questionTemplates/fetchById", doc.question_template, {root: true});
			}
			if( doc.indicator ){
				dispatch("indicators/fetchById", doc.indicator, {root: true});
			}
            return updateStore(doc)
		},
	},
	state: {},
	getters: {
		getQuestionTexts(state, _, rootState ){
			return (id) => {
				let questionText = {
					question_text: "", 
					subquestion_text: "", 
					indicator_title: ""
				};
				let question = state.data[id];
				if( question ){
					questionText.question_text = question.question_text;
					questionText.subquestion_text = question.subquestion_text;
					let indicator = rootState.indicators.data[question.indicator];
					if (indicator) {
						questionText.indicator_title = indicator.title;
					}					
					questionText.string = `${questionText.question_text}`;
					if( question.subquestion_text ){
						questionText.string += ` - ${questionText.subquestion_text}`;
					} 
				}
				return questionText;
			} 
		},		
		getAnswerOptions(state, _, rootState ){
			return (id) => {
				let question = state.data[id];
				if( !question || !question.question_template ){
					return false;
				}
				return rootState.questionTemplates.data[question.question_template] ? rootState.questionTemplates.data[question.question_template].answer_options : "";
			}
		}
	},
	mutations: {},
	actions: {},
};
