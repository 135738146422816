<template>
	<span v-if="canSupport">
			<v-tooltip top>
				<template v-slot:activator="{on}">
				<v-btn v-on="on" color="success" @click="toggle" fab fixed bottom right>
					<v-icon color="white">mdi-monitor-share</v-icon>
				</v-btn>
				</template>
				Start sharing your screen
			</v-tooltip>
		<mw-dialog
			v-model="dialog"
			submitBtn
			@submit="startScreenSharing"
			title
			cancelBtn
			@cancel="stop"
		>
			<template v-slot:button> </template>
			<template v-slot:dialog-body>
				You are about to start sharing your screen with a member of the DMA
				support team. Are you sure you want to do this?
			</template>
		</mw-dialog>
	</span>
</template>

<script>
import Twilio from "twilio-video";
import videoUtilities from "@/assets/videoUtilities.js";
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "StartScreenShareSupport",
	data: () => ({
		token: null,
		room: null,
		dialog: false,
		screen_track: null,
		warn: false,
	}),
	props: {
		mini: { type: Boolean },
	},
	computed: {
		user() {
			return this.$store.state.auth.data;
		},
		canSupport() {
			return this.user.screenshare_support_available;
		},
		roomName() {
			return `${this.user.id}-support`;
		},
	},
	components: {
		MwDialog,
	},
	methods: {
		async retrieveToken() {
			const self = this;
			return videoUtilities
				.getToken(this.roomName, this.user.id)
				.then((res) => {
					self.token = res;
					return;
				});
		},
		endScreensharing() {
			const self = this;
			self.room.localParticipant.unpublishTrack(self.screen_track);
			self.screen_track.stop();
			self.screen_track = null;
		},
		startScreenSharing() {
			const self = this;
			videoUtilities.getScreenTrack().then((track) => {
				self.screen_track = track;
				self.room.localParticipant.publishTrack(track);
			});
		},
		toggle() {
			if (this.screen_track) {
				this.stop();
			} else {
				this.start();
			}
		},
		start() {
			const self = this;
			self.retrieveToken().then(() => {
				Twilio.connect(self.token, {
					name: self.roomName,
					audio: false,
					video: false,
				}).then((room) => {
					self.room = room;
					room.localParticipant.on("trackStopped", () => {
						self.stop();
					});
					self.dialog = true;
				});
			});
		},
		stop(v) {
			if (!v) {
				this.dialog = false;
				if (this.room) {
					this.endScreensharing();
					this.room.disconnect();
				}
			}
		},
	},
	watch: {
		canSupport(v) {
			if (!v) {
				this.stop();
			}
		},
	},
};
</script>
