<template>
	<v-list-item class="px-2 menu-item">
		<v-list-item-icon>
			<v-btn icon @click="dialog = true">
				<v-icon>mdi-account-circle</v-icon>
			</v-btn>
		</v-list-item-icon>
		<v-list-item-title @click="dialog = true">
			{{ $t("auth.login") }}
		</v-list-item-title>
		<v-dialog :eager="true" v-model="dialog" width="360px">
			<div id="ig-firebaseui-auth-container"></div>
		</v-dialog>
	</v-list-item>
</template>

<style lang="less">
@import "../../assets/css/variables.less";
@import "../../../node_modules/firebaseui/dist/firebaseui.css";

#ig-firebaseui-auth-container {
	background-color: white;
	.firebaseui-page-provider-sign-in {
		padding: 30px 0px;
	}
}
.firebaseui-id-submit,
.firebaseui-button,
.mdl-button--raised.mdl-button--colored {
	background: @nhsblue !important;
	color: white !important;
}
</style>

<script type="text/javascript">
import firebase from "firebase";
import * as firebaseui from "firebaseui";
export default {
	name: "Login",
	data: () => {
		return {
			dialog: false,
		};
	},
	computed: {
		path() {
			return window.location.href
		},
	},
	mounted() {
		const self = this;
		var uiConfig = {
			callbacks: {
				signInSuccessWithAuthResult: function(authResult) {
					authResult.user;
					self.dialog = false;
					self.$store.dispatch("auth/start");
				},
			},
			signInOptions: [
				{
					provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
					signInMethod:
						firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
					// disableSignUp: {status: true, adminEmail: "research@m.works" },
					emailLinkSignIn: function() {
						return {
							url: self.path,
						};
					},
				},
				// {
				// 	provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
				// 	whitelistedCountries: ["GB"],
				// },
			],
		};
		var ui =
			firebaseui.auth.AuthUI.getInstance() ||
			new firebaseui.auth.AuthUI(firebase.auth());
		ui.start("#ig-firebaseui-auth-container", uiConfig);
	},
};
</script>
