export default {
	firestorePath: "assessments/{id}",
	firestoreRefType: "doc",
	moduleName: "assessment",
	statePropName: "data",
	namespaced: true,
	state: {},
	getters: {
		id(state) {
			return state.data.id;
		},
	},
	mutations: {},
	actions: {
		retrieveMySectionResponses({ rootGetters, state, dispatch }) {
			var id = state.data.id;
			if (!rootGetters.canAccessAssessment) {
				return;
			}
			var params = [["assessment", "==", id]];
			if (!rootGetters.canAccessWholeAssessment) {
				params.push([
					`users.${rootGetters["auth/id"]}`,
					"==",
					rootGetters.role,
				]);
			}
			dispatch(
				"sectionResponses/openDBChannel",
				{ where: params },
				{ root: true }
			);
		},
		async fetchData({ dispatch, rootGetters, state }) {
			await dispatch("config/start", "default", { root: true });
			await dispatch("indicators/fetchAndAdd", { limit: -1 }, { root: true });
			await dispatch("badges/fetchAndAdd", { limit: -1 }, { root: true });
			if (rootGetters.isLeadership || rootGetters.isDelegate ) {
				await dispatch("users/fetchRespondents", state.data.organisation, {
					root: true,
				});
			}
			return await dispatch("retrieveMySectionResponses");

		},
		async start({ dispatch, commit }, id) {
			commit("assessment/RESET_VUEX_EASY_FIRESTORE_STATE", null, {root: true});
			commit("conference/RESET_VUEX_EASY_FIRESTORE_STATE", null, {root: true});
			commit("sectionResponses/RESET_VUEX_EASY_FIRESTORE_STATE", null, {root: true});
			commit("staffSurveyResponses/RESET_VUEX_EASY_FIRESTORE_STATE", null, {root: true});

			dispatch("themes/fetchAndAdd", false, { root: true });
			dispatch("polls/myPolls", false, { root: true });
			await dispatch("openDBChannel", { pathVariables: { id } });
			return;
		},

	},
};
