import {nanoid} from "nanoid";

import Vue from "vue";

export default {
	firestorePath: "conferences/{id}",
	firestoreRefType: "doc",
	moduleName: "conference",
	statePropName: "data",
	namespaced: true,
	state: {
		data: {
			id: null,
			status: "inactive",
			assessment: null,
			data: {},
			title: "",
			question: "",
			password: null
		} 
	},
	getters: {},
	actions: {
		async change({dispatch}, id) {
			await dispatch("openDBChannel", {pathVariables: {id}});
			await dispatch("patch", {status: 'active'});
			return true;
		},
		start({dispatch, rootState, state}) {
			if (!state.data.id) {
				state.data.id = nanoid(8);
			}
			let assessment = rootState.assessment.data.id;
			state.data.assessment = assessment;
			state.data.password = nanoid(12);
			state.data.status = "active";
			dispatch("openDBChannel", {pathVariables: {id: state.data.id}});
		},
		async end( {dispatch,state} ){
			if( state.data.id ){
				await dispatch("patch", {'status': 'inactive'})
				dispatch('closeDBChannel', {clearModule: true})
			}
			return;
			// commit("RESET")
		}
	},
	mutations: {
		ACTIVATE(state){
			Vue.set(state, "status", "active");
		}
	}
};
