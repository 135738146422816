<template>
	<mw-dialog
		large
		title="Start Conference"
		v-model="dialog"
		cancel-btn
	>
		<template v-slot:button>
			<nav-item
				@click="
					() => {
						active ? toggleConference() : (dialog = true);
					}
				"
				:expanded="!mini"
				:text="active ? 'End Conference' : 'Start Conference'"
				:active="active"
				:icon="active ? 'mdi-close-circle' : 'mdi-plus-circle'"
				help="Conference mode lets you work through sections and questions with
					group of colleagues in real-time"
			>
			</nav-item>
		</template>
		<template v-slot:dialog-body>
			<render-content id="conferenceexplainer"></render-content>
		</template>
		<template v-slot:dialog-buttons>
			<v-btn color="primary" @click="toggleConference">Start</v-btn>
		</template>
	</mw-dialog>
</template>

<script>
	import NavItem from "@c/navigation/Item";
	import MwDialog from "@c/ui/MwDialog.vue";
	import RenderContent from "@c/ui/RenderContent.vue";
	export default {
		name: "StartConference",
		props: {
			mini: Boolean
		},
		data: () => {
			return {
				dialog: false
			};
		},
		computed: {
			current() {
				return this.$store.state.current;
			},
			isLead() {
				return this.$store.getters.isLead;
			},
			conference() {
				return this.$store.state.conference.data;
			},
			active() {
				return this.conference.status == "active";
			},
			inAssessment() {
				return this.$route.name == "Assessment";
			}
		},
		components: {
			NavItem,
			MwDialog,
			RenderContent
		},
		methods: {
			toggleConference() {
				this.dialog = false;
				if (this.active) {
					this.endConference();
				} else {
					this.startConference();
				}
			},
			startConference() {
				this.$store.dispatch("conference/start");
			},
			endConference() {
				this.$store.dispatch("conference/end");
			}
		}
	};
</script>
