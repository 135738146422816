<template>
	<mw-dialog large title="Archived Conferences" v-model="dialog" cancel-btn>
		<template v-slot:button>
			<nav-item
				@click="dialog = true"
				text="Archived Conferences"
				icon="mdi-history"
			>
			</nav-item>
		</template>
		<template v-slot:dialog-body>
			<p>
				To access attendee voting results from archived conferences, select your
				conference below, then navigate to the survey section you want to access
				results for by clicking on "Assessment" in the main navigation menu on
				the left. When you select your section in the survey navigation on the
				left, the conference bar will appear at the bottom of your screen. Click
				on the chart symbol to see attendee voting results.
			</p>
			<v-list v-model="active">
				<v-list-item
					v-for="item in conferences"
					:key="item.id"
					@click="selectConference(item.id)"
				>
					<v-list-item-content>
						<v-list-item-title>
							{{ item.id }} -
							{{ item.created_at.toLocaleDateString() }}
						</v-list-item-title>
					</v-list-item-content>
					<v-list-item-icon v-if="current.id == item.id">
						<v-icon color="success">mdi-star</v-icon>
					</v-list-item-icon>
				</v-list-item>
			</v-list>
		</template>
		<template v-slot:dialog-buttons>
			<v-btn color="primary" @click="dialog = false">Done</v-btn>
		</template>
	</mw-dialog>
</template>

<script>
	import _ from "lodash";
	import NavItem from "@c/navigation/Item";
	import MwDialog from "@c/ui/MwDialog.vue";
	export default {
		name: "ArchivedConferences",
		props: {},
		data: () => {
			return {
				dialog: false,
				active: null
			};
		},
		computed: {
			assessment() {
				return this.$store.state.assessment.data.id;
			},
			conferences() {
				let conferences = Object.values(this.$store.state.conferences.data);
				conferences.forEach((c) => (c.created_at = c.created_at.toDate()));
				conferences = _.sortBy(conferences, "created_at");
				return conferences.reverse();
			},
			current() {
				return this.$store.state.conference.data;
			}
		},
		components: {
			NavItem,
			MwDialog
		},
		methods: {
			async selectConference(id) {
				await this.$store.dispatch("conference/end");
				this.$store.dispatch("conference/change", id);
			}
		},
		watch: {
			assessment: {
				immediate: true,
				handler(value) {
					this.$store.dispatch("conferences/fetchByAssessment", value);
				}
			}
		}
	};
</script>
